import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { Box, Container, styled, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SimplePicture } from '../SimplePicture';

const toSize = (val: number, factor: number) => {
  return Math.round(val * factor).toFixed(0);
};

const Title = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  textAlign: 'center',
  marginBottom: theme.spacing(4),
}));

const ImageContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  flexWrap: 'wrap',
});

export const WellKnown = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const factor = isSmall ? 0.66 : 1;
  const theme = useTheme();

  return (
    <Container data-sectionid={router.pathname === '/' ? 'well-known' : undefined}>
      <Title variant="body2" color={theme.palette.primary.main}>
        {t(`redesignMainPage.wellKnown.title`)}
      </Title>
      <ImageContainer>
        <SimplePicture
          baseImagePath="/images/testimonials/lp/WirtschaftsWoche.png"
          title={t('landingMain.testimonials.wiwo.title')}
          width="145"
          height="60"
          link="https://www.wiwo.de/finanzen/geldanlage/fondskosten-griff-in-die-kasse-der-sparer/25488664.html?ticket=ST-2845557-11qdjBA97AgvTU3Rqum7-ap2"
        />
        <SimplePicture
          baseImagePath="/images/testimonials/lp/Handelsblatt.png"
          title={t('landingMain.testimonials.handelsblatt.title')}
          width={toSize(190, factor)}
          height={toSize(30, factor)}
          link="https://www.handelsblatt.com/finanzen/anlagestrategie/trends/provision-das-bedeutet-ein-provisionsverbot-fuer-banken-und-anleger/28992428.html?mls-token=a78497bab5ba45cad0f5b75ac00161ea5f324260e51b7211b41e1190887b3a65607bde66cfb78cb26a02d35f3bfb75b5028992428"
        />
        <SimplePicture
          baseImagePath="/images/testimonials/lp/DieWelt.png"
          title={t('landingMain.testimonials.welt.title')}
          width={toSize(180, factor)}
          height={toSize(30, factor)}
          link="https://www.welt.de/finanzen/geldanlage/plus170518745/Jetzt-brechen-fuer-Sparer-guenstige-Zeiten-an.html"
        />
        <SimplePicture
          baseImagePath="/images/testimonials/lp/Focus.svg"
          title={t('landingMain.testimonials.focusMoney.title')}
          width={toSize(150, factor)}
          height={toSize(40, factor)}
          link="https://blog.rentablo.de/kundenzufriedenheit-2022/"
        />
        <SimplePicture
          baseImagePath="/images/testimonials/lp/Suddeutsche.png"
          title={t('landingMain.testimonials.sueddeutsche.title')}
          width={toSize(240, factor)}
          height={toSize(30, factor)}
          link="https://www.sueddeutsche.de/wirtschaft/finanzen-fondsvermittler-beteiligen-kunden-oft-an-provisionen-dpa.urn-newsml-dpa-com-20090101-210804-99-708672"
        />
      </ImageContainer>
    </Container>
  );
};
